import React from 'react';
import { useAnimation, m } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
interface Props {
  children?: any;
  animation?: string;
  range?: number;
  once?: boolean;
  variants?: any;
  style?: any;
}
interface Args {
  threshold?: number;
  root?: any;
  rootMargin?: string;
  once?: boolean;
  delay?: number;
}
function useIntersectionObserver(
  elementRef: React.RefObject<Element>,
  { threshold = 0, root = null, rootMargin = '0%', once = false }: Args
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = React.useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && once;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  React.useLayoutEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);
    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen]);

  return entry;
}

const useAnimateInView = ({ once, threshold = 0, delay = 0 }: Args) => {
  const controls = useAnimation();
  const [forceView, setForceView] = React.useState(false);
  // const ref = React.useRef<any>();
  const { ref, inView, entry } = useInView({ threshold, delay, rootMargin: '-100px 0px', triggerOnce: true });
  // const entry = useIntersectionObserver(ref, { threshold, delay });
  React.useLayoutEffect(() => {
    if (forceView || inView || entry?.isIntersecting) {
      controls.start('visible');
    } else if (once == false) {
      controls.start('hidden');
    }

    setTimeout(() => {
      if (entry?.boundingClientRect?.top ?? 0 < window.innerHeight) {
        setForceView(true);
        controls.start('visible');
      }
    }, 300);
  }, [forceView, controls, inView]);
  return { ref, animate: controls };
};

const FadeIn = ({
  children,
  once,
  threshold = 0.1,
  delay,
  variants = { hidden: { opacity: 0 }, visible: { opacity: 1 } },
  ...rest
}: Props & Args) => {
  const { ref, animate } = useAnimateInView({ once, threshold, delay });
  return (
    <m.div ref={ref} animate={animate} {...rest} initial="hidden" variants={variants}>
      {children}
    </m.div>
  );
};

export default FadeIn;
